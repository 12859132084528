import React, { useEffect } from 'react';

import { isFalsy } from '@leapfinance/frontend-commons';
import { useRouter } from 'next/router';

import { useAppSelector } from '@/app/hooks';
import LoanMelaAwarenessPopper from '@/components/Tiering/LoanMelaAwarenessPopper';
import { selectUserDetails } from '@/features/user/userSlice';
import { Permissions } from '@/permissions/featuresList';
import { trackClick } from '@/segment/eventTracker';
import { useLazyCheckCspPendingPaymentsQuery } from '@/services/cspPerformanceApi';
import { useGetRewardCreditedEventQuery } from '@/services/rewardCredited';
import { PageNames, WidgetFormat, WidgetName } from '@/types/segmentEvents';
import { localStorageKeys } from '@/utils/GBLocalStorageUtil';
import { shouldShowPopup } from '@/utils/newsletterFeatureUtil';

import { MODAL_TYPES } from './Modals/GlobalModal';
import useModal from './Modals/useModal';

const INTERNATIONAL_GETAWAY_MODAL_DISABLE_DATE = `2025-02-10 23:59:59`;
const COVENTRY_ADMISSION_DRIVE_DISABLE_DATE = `2025-02-10 23:59:59`;

const AnnouncementWrapper = (props: { children: React.ReactNode }) => {
  const { showModal } = useModal();
  const router = useRouter();
  const { data: rewardCreditedEventData } = useGetRewardCreditedEventQuery(
    undefined,
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const [triggerCheckPendingPayment, { data: pendingPayment }] =
    useLazyCheckCspPendingPaymentsQuery();

  const cspProfileData = useAppSelector((state) => selectUserDetails(state));

  const isInternationalCsp = cspProfileData?.cspZone === `International`;

  const isStudentUser =
    cspProfileData?.permissions?.[0] === Permissions.STUDENT;

  const isAdminUser = cspProfileData?.permissions?.[0] === Permissions.ADMIN;

  const isBranchCsp = cspProfileData?.cspZone === `Branches`;

  useEffect(() => {
    const checkPendingPayment = async () => {
      if (router?.pathname?.toString() === `/summary-dashboard`) {
        await triggerCheckPendingPayment();
      }
    };

    checkPendingPayment();
  }, []);

  useEffect(() => {
    if (
      isFalsy(cspProfileData?.permissions) ||
      isStudentUser ||
      !router?.isReady
    )
      return;
    const routesWhereToNotShow = [
      `/`,
      `/login`,
      `/changepwd`,
      `/resetpwd`,
      `/test-prep/video-course`,
      `/test-prep/video-course/[slug]`,
      `/test-prep/mock-test`,
      `/test-prep/mock-test/[mock_test_id]`,
      `/test-prep/mock-test/result/[mock_test_id]`,
      `/test-prep/mock-test/speaking/result/[id]`,
      `/ielts`,
      `/student-login`,
    ];

    if (routesWhereToNotShow?.includes(router?.pathname?.toString())) return;

    if (
      rewardCreditedEventData?.data?.includes(`PAYMENT_APPLICATION`) ||
      rewardCreditedEventData?.data?.includes(`STI_APPLICATION`)
    ) {
      showModal({
        modalType: MODAL_TYPES.REWARDS_NOTIFY_MODAL,
        modalProps: {
          type: rewardCreditedEventData?.data?.includes(`PAYMENT_APPLICATION`)
            ? `payment`
            : rewardCreditedEventData?.data?.includes(`STI_APPLICATION`)
            ? `application`
            : null,
        },
      });
    }

    const showCoventryAdmissionDrive =
      isAdminUser &&
      !isInternationalCsp &&
      !isBranchCsp &&
      shouldShowPopup({
        key: localStorageKeys.COVENTRY_ADMISSION_DRIVE,
        skipTimeSlot: true,
        modalDisableDateTime: COVENTRY_ADMISSION_DRIVE_DISABLE_DATE,
        maxTimesToShow: 15,
        maxTimesToShowPerDay: 3,
        minGapHours: 4,
        // minGapHoursFromOtherModals: 2,
        // referenceModal: localStorageKeys.INTERNATIONAL_GETAWAY_MODAL_DISPLAY,
      });

    const showInternationalGetawayModal =
      isAdminUser &&
      !isInternationalCsp &&
      shouldShowPopup({
        key: localStorageKeys.INTERNATIONAL_GETAWAY_MODAL_DISPLAY,
        skipTimeSlot: true,
        modalDisableDateTime: INTERNATIONAL_GETAWAY_MODAL_DISABLE_DATE,
        maxTimesToShow: 15,
        maxTimesToShowPerDay: 3,
        minGapHours: isBranchCsp ? 2 : 4,
        referenceModal: localStorageKeys.COVENTRY_ADMISSION_DRIVE,
        minGapHoursFromReferenceModal: 2,
      });

    if (
      showCoventryAdmissionDrive &&
      !routesWhereToNotShow?.includes(router?.pathname?.toString())
    ) {
      showModal({
        modalType: MODAL_TYPES.NO_RESULTS,
        modalProps: {
          body: (
            <LoanMelaAwarenessPopper
              redirectionUrl={`#`}
              imageUrl={`https://ik.imagekit.io/onsnhxjshmp/geebee-emails/2_oAUK-t7ap.png?updatedAt=1738754403375`}
              storageKey={localStorageKeys.COVENTRY_ADMISSION_DRIVE}
              containerClassName="max-w-[650px]"
              hideImageClick={true}
              onModalClose={() => {
                trackClick({
                  pageName: PageNames.COVENTRY_ADMISSION_DRIVE_MODAL,
                  contentFormat: `Modal`,
                  contentName: `Close`,
                  widgetFormat: WidgetFormat.MODAL,
                  widgetName: WidgetName.COVENTRY_ADMISSION_DRIVE_MODAL,
                });
              }}
            />
          ),
        },
      });
      return;
    }

    if (
      showInternationalGetawayModal &&
      !routesWhereToNotShow?.includes(router?.pathname?.toString())
    ) {
      showModal({
        modalType: MODAL_TYPES.NO_RESULTS,
        modalProps: {
          body: (
            <LoanMelaAwarenessPopper
              redirectionUrl={`https://forms.gle/gG5CBnDTYuh5VUAt8`}
              imageUrl={`https://ik.imagekit.io/onsnhxjshmp/geebee-emails/Slides%20for%20partners_WUpYBHPqO.jpg?updatedAt=1738754402700`}
              storageKey={localStorageKeys.INTERNATIONAL_GETAWAY_MODAL_DISPLAY}
              containerClassName="max-w-[650px]"
              onImageClick={() => {
                trackClick({
                  pageName: PageNames.INTERNATIONAL_GETAWAY_MODAL,
                  contentFormat: `Modal`,
                  contentName: `Apply Now`,
                  widgetFormat: WidgetFormat.MODAL,
                  widgetName: WidgetName.INTERNATIONAL_GETAWAY_MODAL,
                });
              }}
              onModalClose={() => {
                trackClick({
                  pageName: PageNames.INTERNATIONAL_GETAWAY_MODAL,
                  contentFormat: `Modal`,
                  contentName: `Close`,
                  widgetFormat: WidgetFormat.MODAL,
                  widgetName: WidgetName.INTERNATIONAL_GETAWAY_MODAL,
                });
              }}
            />
          ),
        },
      });
      return;
    }
  }, [
    router,
    isInternationalCsp,
    cspProfileData,
    pendingPayment,
    rewardCreditedEventData,
  ]);

  return <>{props.children}</>;
};

export default AnnouncementWrapper;
